// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("C:\\Users\\c1dil\\Documents\\play\\gatsby\\cdmonkey\\src\\templates\\blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\c1dil\\Documents\\play\\gatsby\\cdmonkey\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\c1dil\\Documents\\play\\gatsby\\cdmonkey\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\c1dil\\Documents\\play\\gatsby\\cdmonkey\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-musings-js": () => import("C:\\Users\\c1dil\\Documents\\play\\gatsby\\cdmonkey\\src\\pages\\musings.js" /* webpackChunkName: "component---src-pages-musings-js" */)
}

